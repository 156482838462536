




































































import { Component, Vue, Watch } from 'vue-property-decorator'
import TheMainPage from '@/components/Common/TheMainPage.vue'
import TheHeader from '@/components/Common/TheHeader.vue'
import TheSideNavigation from '@/components/Common/TheSideNavigation.vue'
import TheTitle from '@/components/Common/TheTitle.vue'
import AIScriptList from '@/components/AI/AIScriptList.vue'
import AITemplateList from '@/components/AI/AITemplateList.vue'
import { useGetAIScripts, useGetAITemplates } from '@/hooks/openAi'
import { reactive } from '@vue/composition-api'
import get from 'lodash.get'

@Component({
  components: {
    TheMainPage,
    TheHeader,
    TheSideNavigation,
    TheTitle,
    AIScriptList,
    AITemplateList,
  },
  setup() {
    const allScriptsParam: any = reactive({
      paginate: true,
      limit: 10,
      offset: 0,
    })
    const { data: aiScriptList, isFetching: isScriptLoading } = useGetAIScripts(allScriptsParam)

    const { data: aiTemplateList, isFetching: isTemplateLoading } = useGetAITemplates()
    return {
      aiScriptList,
      aiTemplateList,
      isScriptLoading,
      isTemplateLoading,
      allScriptsParam,
    }
  },
})
export default class ViewAi extends Vue {
  aiScriptList!: any
  aiTemplateList!: any
  allScriptsParam!: any
  isScriptLoading!: boolean
  isTemplateLoading!: boolean
  currentTab: string = 'TEMPLATES'
  pageNo = 1
  increment = 10
  timer: number = 0

  get pageCount() {
    return Math.ceil(get(this.aiScriptList, 'count', 0) / 10)
  }

  get list() {
    return get(this.aiScriptList, 'data', [])
  }

  get templateList() {
    return get(this, 'aiTemplateList', [])
  }

  created() {
    this.handleTab()
    this.$watch(
      () => this.$route,
      () => {
        this.handleTab()
      }
    )
  }

  handleTab() {
    const tab = this.$route.params.tab
    switch (tab) {
      case 'templates':
        this.currentTab = 'TEMPLATES'
        break
      case 'bookmarks':
        this.currentTab = 'BOOKMARKS'
        break
      default:
        this.currentTab = 'TEMPLATES'
        break
    }
  }

  mounted() {
    this.allScriptsParam.offset = (this.pageNo - 1) * this.increment
  }

  @Watch('pageNo')
  watchPageNo() {
    this.allScriptsParam.offset = (this.pageNo - 1) * this.increment
  }

  async next() {
    if (this.pageNo + 1 <= this.pageCount) {
      this.pageNo += 1
    }
  }

  async prev() {
    if (this.pageNo > 1) {
      this.pageNo -= 1
    }
  }

  async OnPageNumberChanged(page: number) {
    if (this.timer) {
      window.clearTimeout(this.timer)
    }
    this.timer = window.setTimeout(async () => {
      if (page <= this.pageCount && page > 0) {
        this.pageNo = page
      }
    }, 600)
  }
}
